<template>
  <div class="wrapper">
    <b-container fluid>
      <div class="container">
        <b-navbar
          toggleable="lg"
          v-bind:type="'light'"
          data-aos="fade-down"
          sticky
        >
          <b-navbar-brand href="/">
            <img src="../assets/logo.svg" alt="Codelos.io Logo"/>
            Codelos.io
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item-dropdown text="Lösungen" id="solutions-menu" right>
                <ul>
                  <li>
                    <b-dropdown-item href="/#sales-section"
                      >Für den Vertrieb</b-dropdown-item
                    >
                  </li>
                  <li>
                    <b-dropdown-item href="/#data-section"
                      >Für das Datenmanagement</b-dropdown-item
                    >
                  </li>
                  <li>
                    <b-dropdown-item href="/#automators-section"
                      >Für Citizen Automators</b-dropdown-item
                    >
                  </li>
                  <li>
                    <b-dropdown-item href="/#developers-section"
                      >Für Softwareentwickler</b-dropdown-item
                    >
                  </li>
                </ul>
              </b-nav-item-dropdown>
              <b-nav-item href="/developer" right>Developer</b-nav-item>
              <b-nav-item href="/about" right>Über uns</b-nav-item>
              <b-nav-item href="https://codelos.notion.site/Wir-freuen-uns-auf-deine-Bewerbung-e066250a894a42b9af365c7ffa518dd7" target="_blank" right>Jobs</b-nav-item>
              <b-nav-item href="https://www.app.codelos.io/" right
                >Login</b-nav-item
              >
            <b-nav-item href="mailto:team@codelos.io" right>Kontakt aufnehmen</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
        <div class="header text-center my-5">
          <div class="row">
            <div
              class="
                col-md-12 col-xl-12
                d-flex
                py-5
                mt-5
                align-items-center
                aos-init aos-animate
              "
              data-aos="fade-up"
            >
              <div>
                <h1>👋 Servus!</h1>
                <p>
                  Wir sind ein Team leidenschaftlicher Produktivitätshacker und
                  Weltverbesserer, das es sich zur Aufgabe gemacht hat, die Art
                  und Weise wie Menschen arbeiten, zu verändern. Wir wissen, dass
                  eine Transition zu einer nachhaltigen, besseren Welt nur durch
                  effizientere Kooperation, schnelleren Zugriff auf Daten und
                  einen höheren Automatisierungsgrad zu erreichen ist. Dafür setzen wir
                  uns bei Codelos.io ein.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
    <!-- Team faces -->
    <b-container>
      <div class="section">
        <div class="container text-dark mt-0" data-aos="fade-up">
          <div class="row">
            <div
              class="
                col-md-12 col-xl-12
                d-flex
                py-5
                align-items-center
                text-center
              "
            >
              <h1 class="text-center">Den Namen Gesichter geben</h1>
            </div>
          </div>
          <!-- Founder and Partners -->
          <div class="row team">
            <div
              class="
                col-md
                description-leftight
              "
            >
              <img
                class="icon icon-s px-3"
                src="../assets/louq-round.png"
                alt="Louq Gharnati CEO und Founder Codelos.io"
              />
              <h3>Kicker-Legende</h3>
              <h2>Louq Gharnati<br />Founder & CEO</h2>
              <p>
                Hat vorher bei FSE Portfolio Management die IT-Abteilung
                gegründet und geleitet, die erste real Time PFC im Markt
                entwickelt und eine umfassende Cloud-Lösung für das
                Energiehandel “Energy Trading Cockpit” von Scratch gebaut.
              </p>
              <p></p>
            </div>
            <div class="col-md description-leftight">
              <img
                alt="Dr. Janik Kailasvuori SME und Gesellschafter Codelos.io"
                class="icon icon-s px-3"
                src="../assets/janik-round.png"
              />
              <h3>Formel-Künstler</h3>
              <h2>Dr. Janik Kailasvuori<br />SME & Partner</h2>
              <p>
                Der Mann für komplexe energiewirtschaftliche Prozesse. Ehemaliger Forscher des Max-Planck-Instituts. Er hat vorher bei FSE Portfolio Management die PFM-Systeme umgewälzt und systematische Klarheit in etwa 30 EVU-Portfolien verschafft.
              </p>
              <p></p>
            </div>
            <div class="col-md description-leftight">
              <img
                alt="Stefan Hunziker CTO und Gesellschafter Codelos.io"
                class="icon icon-s px-3"
                src="../assets/stefan-round.png"
              />
              <h3>Shortcuts-Meister</h3>
              <h2>Stefan Hunziker<br />CTO & Partner</h2>
              <p>
                Der Hacker und unsere Geheimwaffe in Cloud und Software. Schreibt vier
                Programmier-sprachen auf Muttersprachniveau und hat mehrere Apps
                mit Tausenden von Benutzern ausgeliefert.
              </p>
              <p></p>
            </div>
          </div>
          <!-- Growth -->
          <div class="row team">
            <div class="col-md description-leftight">
              <img
                alt="Team Codelos.io"
                class="icon icon-s px-3"
                src="../assets/lisandro.png"
              />
              <h3>Zeitzonen-Zickzaker</h3>
              <h2>Lisandro Zabala</h2>
              <h4>Business development Manager</h4>
              <p></p>
            </div>
            <div class="col-md description-leftight">
              <img
                alt="Team Codelos.io Erika Chechel"
                class="icon icon-s px-3"
                src="../assets/laura.png"
              />
              <h3>Tech-Ninja</h3>
              <h2>Laura Mertens</h2>
              <h4>Business development Manager</h4>
              <p></p>
            </div>
            <div class="col-md description-leftight">
              <img
                alt="Team Codelos.io Erika Chechel"
                class="icon icon-s px-3"
                src="../assets/youness.png"
              />
              <h3>Milchschaum-Connaisseur</h3>
              <h2>Youness Attoumi</h2>
              <h4>UI/UX Designer</h4>
              <p></p>
            </div>
          </div>
          <!-- Project manager -->
          <div class="row team">
            <div class="col-md description-leftight">
              <img
                alt="Team Codelos.io"
                class="icon icon-s px-3"
                src="../assets/michael.png"
              />
              <h3>Rechtschreibung-Polizist</h3>
              <h2>Michael Hansen</h2>
              <h4>Project Manager</h4>
              <p></p>
            </div>
            <div class="col-md description-leftight">
              <img
                alt="Robin Hunziker Codelos.io"
                class="icon icon-s px-3"
                src="../assets/robin.png"
              />
              <h3>Knight-Online-Athlet</h3>
              <h2>Robin Hunziker</h2>
              <h4>Project Manager</h4>
              <p></p>
            </div>
            <div class="col-md description-leftight">
              <img
                alt="Team Codelos.io Manthan Deshmukh"
                class="icon icon-s px-3"
                src="../assets/manthan.png"
              />
              <h3>Workaround-Spezialist</h3>
              <h2>Manthan Deshmukh</h2>
              <h4>Project Manager</h4>
              <p></p>
            </div>
          </div>
          <!-- Marketing -->
          <div class="row team">
            <div
              class="
                col-md
                description-leftight
              "
            >
              <img
                alt="team codelos.io"
                class="icon icon-s px-3"
                src="../assets/tapan.png"
              />
              <h3>Edward Snowden</h3>
              <h2>Tapan Chaudhari</h2>
              <h4>Software developer</h4>
              <p></p>
            </div>
            <div class="col-md description-leftight">
              <img
                alt="Team Codelos.io Erika Chechel"
                class="icon icon-s px-3"
                src="../assets/erika-round.png"
              />
              <h3>Undercover-Nerd</h3>
              <h2>Erika Chechel</h2>
              <h4>Marketing Managerin</h4>
              <p></p>
            </div>
            <div class="col-md description-leftight">
              <img
                alt="Team Codelos.io Roman Medwinski"
                class="icon icon-s px-3"
                src="../assets/paul.png"
              />
              <h3>Fun-Fact-Enzyklopädie</h3>
              <h2>Paul Hastert</h2>
              <h4>Citizen Developer</h4>
              <p></p>
            </div>
          </div>
          <!-- Dev -->
          <div class="row team">
            <div class="col-md description-leftight">
              <img
                alt="Team Codelos.io"
                class="icon icon-s px-3"
                src="../assets/hrutvik-round.png"
              />
              <h3>Lebender Sci-Fi-Katalog</h3>
              <h2>Hrutvik Kapadia</h2>
              <h4>Software developer</h4>
              <p></p>
            </div>
            <div class="col-md description-leftight">
              <img
                alt="Team Codelos.io"
                class="icon icon-s px-3"
                src="../assets/dhruvi.png"
              />
              <h3>Secret DaVinci</h3>
              <h2>Dhruvi Shah</h2>
              <h4>Software developer</h4>
              <p></p>
            </div>
            <div class="col-md description-leftight">
              <img
                alt="Team Codelos.io"
                class="icon icon-s px-3"
                src="../assets/sagar-round.png"
              />
              <h3>Chess-Ace</h3>
              <h2>Sagar Khatri</h2>
              <h4>Software developer</h4>
              <p></p>
            </div>
          </div>
           <!-- Dev -->
           <div class="row team">
            <div
              class="
                col-md
                description-leftight
              "
            >
              <img
                alt="team codelos.io"
                class="icon icon-s px-3"
                src="../assets/kaushal.png"
              />
              <h3>Gandalf der Weise</h3>
              <h2>Kaushal Panchal</h2>
              <h4>Software developer</h4>
              <p></p>
            </div>
            <div class="col-md description-leftight">
            </div>
            <div class="col-md description-leftight">
            </div>
          </div>
        </div>
      </div>
    </b-container>
    <!-- Team values -->
    <b-container>
      <div class="section">
        <div class="container text-dark mt-0">
          <div class="row">
            <div
              class="
                col-md-12 col-xl-12
                d-flex
                mt-5
                py-5
                align-items-center
                text-center
              "
            >
              <h1 class="text-center">Unsere Werte</h1>
            </div>
          </div>
        </div>
      </div>
    </b-container>
    <b-container>
      <div class="section">
        <div class="container text-dark mt-0 gray">
          <div class="row values justify-content-center align-self-center">
            <div class="col-md description-leftight">
              <h3>Speed</h3>
              <h2>Wir sind Schrittmacher</h2>
              <p>
                Wir lieben es, wenn das Tempo hoch ist. Als Startup ist unser
                größter Vorteil Enthusiasmus, Agilität und
                Ausführungsgeschwindigkeit. Wir wollen in unserem Markt das
                Tempo vorgeben, indem wir ständig neue Dinge liefern.
              </p>
              <div class="row" data-aos="fade-right">
                <img
                  alt="Team Codelos.io"
                  class="icon icon-s"
                  src="../assets/running.svg"
                  align="left"
                />
              </div>
            </div>
            <div class="col-md description-leftight">
              <h3>Transparenz</h3>
              <h2>Wir sind offen und ehrlich</h2>
              <p>
                Wir vertrauen darauf, dass ein offener Zugang zu Informationen
                die besten Möglichkeiten ist, anderen und uns selbst zu
                helfen. Aus diesem Grund sind wir immer transparent
                gegenüber unseren Kunden und innerhalb unseres Teams.
              </p>
              <div class="row" data-aos="fade-right">
                <img
                  alt="Team Codelos.io"
                  class="icon icon-s"
                  src="../assets/aquarium.svg"
                  align="left"
                />
              </div>
            </div>
          </div>
          <div class="row values" id="aotomators">
            <div
              class="
                col-md
                description-leftight
                justify-content-center
                align-self-center
              "
            >
              <h3>Freiheit</h3>
              <h2>Wir behandeln uns wie Erwachsene</h2>
              <p>
                Unser Ziel ist es, Menschen mehr zu begeistern, als sie zu
                führen. Wir behandeln uns wie Erwachsene. Jeder arbeitet wo er
                will, wann er will und ist trotzdem durch Einsatz der richtigen
                Tools und durch absolute Transparenz getrieben, eine großartige
                Arbeit zu leisten.
              </p>
              <div class="row" data-aos="fade-right">
                <img
                  alt="Team Codelos.io"
                  class="icon icon-s"
                  src="../assets/fire.svg"
                />
              </div>
            </div>
            <div class="col-md description-leftight">
              <h3>Spaß</h3>
              <h2>Wir nehmen uns nicht zu ernst</h2>
              <p>
                Wir lieben, was wir tun, und wir arbeiten hart daran, die Dinge
                schnell zu liefern, aber wir nehmen uns auch nicht zu ernst! Ein
                entspanntes und freundliches Umfeld ist der Schlüssel zu
                besseren Ergebnissen in unserem Team und bei unseren Kunden.
              </p>
              <div class="row" data-aos="fade-right">
                <img
                  alt="Team Codelos.io"
                  class="icon icon-s"
                  src="../assets/fun.svg"
                />
              </div>
            </div>
          </div>
          <div class="row values">
            <div class="col-md" align="right" data-aos="fade-left"></div>
          </div>
        </div>
      </div>
    </b-container>
    <!-- Join us -->
    <b-container>
      <div class="header text-center my-5">
          <div class="row">
            <div
              class="
                col-md-12 col-xl-12
                d-flex
                py-5
                mt-5
                align-items-center
                aos-init aos-animate
              "
              data-aos="fade-up"
            >
              <div>
                <h1>🙌 Werde Teil unseres Teams!</h1>
                <p>
                  Möchtest du uns dabei unterstützen, unsere bahnbrechende AIA No-Code-Plattform weltweit zu präsentieren, benutzerfreundliche und innovative Komponenten zu entwickeln oder unseren Kunden dabei helfen, den größten Mehrwert aus der Plattform zu ziehen? Dann bewirb dich gerne auf eine der folgenden Stellen 🚀🚀
                </p>
                <a data-v-c84e68c4="" href="https://codelos.notion.site/Wir-freuen-uns-auf-deine-Bewerbung-e066250a894a42b9af365c7ffa518dd7?pvs=4" target="_blank" class="btn">Stellenangebote</a>
              </div>
            </div>
          </div>
        </div>
    </b-container>
    <b-container>
      <div class="footer">
        © Codelos.io GmbH
        <br /><br />
        Mit ❤️ gemacht in München, Berlin, Porto und Ko Samui.
        <br /><br />
        <ul class="inline-list">
          <li>
            <u><a href="/impressum">Impressum</a></u>
          </li>
          <li>
            <u><a href="/privacy">Datenschutzerklärung</a></u>
          </li>
          <li>
            <a
              href="https://www.facebook.com/Codelosio-GmbH-103666008172517"
              target="blank"
              class="mr3"
              ><i class="fab fa-facebook-f"></i
            ></a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/codelos"
              target="blank"
              class="mr3"
              ><i class="fab fa-linkedin-in"></i
            ></a>
          </li>
          <!--<li>
              <a
                href="https://www.xing.com/pages/codelos-iogmbh"
                target="blank"
                class="mr3"
                ><i class="fab fa-xing"></i
              ></a>
            </li>-->
          <li>
            <a
              href="https://www.instagram.com/codelos.io/"
              target="blank"
              class="mr3"
              ><i class="fab fa-instagram"></i
            ></a>
          </li>
        </ul>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      loaded: false,
      navbarType: "light",
    };
  },
  methods: {
    changeNavbarType() {
      this.navbarType == (this.navbarType == "light" ? "dark" : "light");
    },
  },
  mounted() {
    setTimeout(() => (this.loaded = true), 9000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  height: 100%;
}

/** Navbar **/
/** Navbar **/
.navbar-brand img {
  height: 40px;
}

.navbar-light {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  
}

.nav-link.dropdown-toggle {
  color: #000;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #000;
}

::v-deep ul.dropdown-menu {
  background: #ffff;
  margin-top: 18px;
  list-style-type: none!important;
}

::v-deep .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.28rem 2rem;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

::v-deep .dropdown-toggle {
  outline: none;
}

::v-deep .dropdown-menu {
  background: #ffff;
  margin-top: 18px;
}

::v-deep .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.28rem 2rem;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

::v-deep .dropdown-toggle {
  outline: none;
}

/** Container **/
.container {
  height: 100%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}

@media screen and (max-width: 479px) {
  .container {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.header {
  text-align: left;
}

h1 {
  font-size: 3rem;
}

.header h1 {
  margin: 0px;
  font-size: 91px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -0.035em;
  text-transform: none;
  margin-bottom: 25px;
}

.row .team h2{
  font-size: 1.77777778rem;
}

.header p {
  margin-bottom: 5vh;
  font-size: calc(0.7rem + 0.6vw);
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: -0.01em;
  font-family: Pretendard, sans-serif;
}

.lead {
  padding-top: 60px;
  padding-bottom: 60px;
}



.btn-primary:hover {
  background-color: #d4d1d1;
}

video {
  width: 100%;
  border-radius: 30px;
}

.demos {
  margin-top: 25px;
  color: #000;
  font-size: 20px;
  font-weight: 500;
}

.preview-element {
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 31%);
  transition-property: border-color;
  transition-duration: 350ms;
}

.video-wrapper {
  padding: 0;
  margin-top: 6%;
  margin-bottom: 6%;
}

.orange {
  background-color: #ff7745;
}

.blue {
  background-color: #3b85f7;
}

.mauve {
  background-color: #944ff6;
}

.green {
  background-color: #6eecd5;
}

.gray {
  background-color: #cdcdcd;
}

.light-gray-blue {
  background: linear-gradient(-45deg, #fff2f5, #f7f5ff, #edfbff);
  background: #f2f8ff;
}

.black {
  background: black;
  color: white;
}

.black .btn-primary {
  background-color: white;
  color: black;
}

.light-gray {
  background-color: #f8f9fc;
}

.preview-element .title {
  text-align: center;
  padding: 19px;
  font-size: 19px;
  font-weight: 500;
  background: #ffff;
}

/* footer */
.footer {
  padding-top: 110px;
  text-align: left;
  padding-bottom: 50px;
  padding-left: 0;
}

.footer a {
  text-decoration: none;
  color: inherit;
}

.footer ol,
ul {
  padding-left: 0px;
}

.inline-list {
  list-style: unset;
}

.inline-list li {
  display: inline-block;
  margin-right: 15px;
}

.inline-list li a:hover {
  text-decoration: underline;
}
/* end footer */

a.dropdown-item {
  background-color: #212529;
}

.dropdown-menu {
  border-radius: 0px;
}

.highlight {
  background-image: linear-gradient(
    to right,
    rgb(255 231 133),
    rgb(255 231 133)
  );
  background-size: 0% 1.12em;
  background-repeat: no-repeat;
  background-position: left bottom;
  transition: background-size 2000ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

.active .highlight {
  background-size: 100% 1.12em;
}

.drag-n-drop-anim {
  animation: drag;
  animation-duration: 3s;
  position: relative;
  display: block;
}

.change-anim-list {
  overflow: hidden;
  height: 70px;
}

.change-anim-item {
  animation-name: change;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  position: relative;
  display: block;
}

.fa-mouse-pointer {
  font-size: 38px;
  color: black;
  margin-top: -22px;
  display: block;
  margin-left: 49px;
  -webkit-text-fill-color: #000;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ffff;
  height: 22px;
}

@keyframes change {
  0%,
  12.66%,
  100% {
    top: 0px;
  }
  16.66%,
  29.32% {
    top: -85px;
  }
  33.32%,
  45.98% {
    top: -170px;
  }
  49.98%,
  62.64% {
    top: -255px;
  }
  66.64%,
  79.3% {
    top: -340px;
  }
  83.3%,
  95.96% {
    top: -425px;
  }
}

@keyframes drag {
  0% {
    left: -600px;
    top: 900px;
  }
  30% {
    left: -600px;
    top: 900px;
  }
  60% {
    left: 60px;
    top: 20px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}

@keyframes zoom-in {
  0% {
    width: 0%;
    height: 0px;
  }
  75% {
    width: 30%;
    height: 50px;
  }
  100% {
    width: 100%;
    height: 148px;
  }
}

@keyframes show-menu {
  0% {
    opacity: 0;
    margin-left: -20px;
    width: 60%;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}

@media screen and (max-width: 479px) {
  .header h1 {
    font-size: 50px;
  }
  .header p {
    padding-bottom: 25px;
  }
  .preview-element {
    margin-top: 45px;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .nav-item {
    padding-right: 0.7rem;
    padding-left: 0.7rem;
  }

  ::v-deep .navbar-expand-lg .navbar-nav .dropdown-menu {
    width: fit-content;
    left: auto;
    right: auto;
    -webkit-animation: show-menu-data-v-8dc7cce2;
    animation: show-menu-data-v-8dc7cce2;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    border-radius: 7px;
    box-shadow: rgb(51 51 51 / 50%) 0px 20px 40px -30px;
    border: solid 0.6px #eeecf2;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #f0f0f0;
    color: white;
    font-weight: 900;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-menu-content {
    margin: 100px;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu h2 {
    font-size: 17px;
    font-weight: 700;
    padding: 0rem 0rem 1em 2rem;
    border-bottom: solid 1px #cdcdcd;
    margin-bottom: 1rem;
  }

  ::v-deep .dropdown-item {
    font-size: 16px;
  }
}

/*@media (min-width: 1200px) {

}*/

::v-deep .dropdown-menu {
  padding: 27px 0 26px;
}


.container.gray {
  background-color: #f7f7f9;
  border-radius: 22px;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (min-width: 700px)  {
  .container.gray {
    padding: 90px;
  }
}


.header img {
  box-shadow: 0 1px 10px rgb(0 0 0 / 7%), 0 2.4px 5.7px rgb(0 0 0 / 2%),
    0 4px 11.3px rgb(0 0 0 / 2%), 0 10.6px 17.5px rgb(0 0 0 / 2%),
    0 14px 25.7px rgb(0 0 0 / 3%), 0 35.5px 39.6px rgb(0 0 0 / 4%),
    0 40px 48px rgb(0 0 0 / 3%);
  border-radius: 9px;
}

.screens-wrapper {
  width: 100%;
}

.screen-1 {
  margin-top: 36%;
  left: 5%;
  z-index: 1;
  position: relative;
  float: left;
  width: 39%;
}

.screen-2 {
  left: 0px;
  margin-top: 0px;
  position: relative;
  float: right;
  width: 61%;
}

.row.team img {
  width: 100%;
  border-radius: 22px;
  margin-top: 6%;
  margin-bottom: 6%;
}

.row.team .col {
  width: 100px;
}

.row.values img {
  width: unset;
  max-height: 200px;
  margin-right: 0;
  margin-left: auto;
}

.container {
  max-width: 1443px;
}

.container-fluid {
  width: 100%;
}

@media screen and (min-width: 479px) {
  .container-fluid .container {
    padding-right: 60px;
    padding-left: 60px;
  }
}

h3 {
  font-size: calc(0.7rem + 0.6vw);
  width: 100%;
}

h2 {
  font-family: Avenir Next, Avenir Next W01, Avenir, helvetica, arial;
  font-size: 1.77777778rem;
  line-height: 2.66666667rem;
  margin-bottom: 0.5em;
  font-weight: 700;
  width: 100%;
}

h1 {
  font-family: Avenir Next, Avenir Next W01, Avenir, helvetica, arial;
  line-height: 2.66666667rem;
  margin-bottom: 0.5em;
  font-weight: 700;
  width: 100%;
}

@media (min-width: 1200px) {
  h2 {
    font-size: 2.3rem;
  }
}

.description-right {
  padding-left: 5%;
}

.description-left {
  padding-right: 5%;
}

.ml-auto {
  margin-left: auto !important;
}

.navbar-dark .navbar-brand {
  color: #e4e1e1;
  font-size: 27px;
  font-weight: 500;
}

::v-deep .navbar-dark .navbar-nav .nav-link {
  color: rgb(255 255 255) !important;
  font-weight: 700 !important;
}

.navbar {
  color: black;
}

.icon icon-s {
  height: 100%;
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.text-dark {
  color: var(--bs-body-color) !important;
}

ul {
  list-style-type: none;
  list-style: none;
}

.btn {
    text-decoration: none;
    color: black;
    border: 2px solid black;
    border-radius: 0.375em;
    cursor: pointer;
    display: inline-block;
    font-family: proxima-nova, "Helvetica Neue", Arial, sans-serif;
    font-weight: 600;
    line-height: 23px;
    padding: 1.5rem 3.5rem;
    white-space: pre;
    font-size: 24px;
    opacity: 1;
    transition: transform 0.2s linear 0s, background-color 0.2s linear 0s;
    transform: translateY(0px) scale(1.001);
}
</style>
